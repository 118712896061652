import Image from '../UI/Image';
import RichText from '../UI/Richtext';
import CTA from '../UI/Cta';
import { ReactElement } from 'react';
import { IComponentImageLeftTextRightFields } from 'types/contentful';

export interface LeftImageRightTextProps extends IComponentImageLeftTextRightFields {
  rightImage?: boolean;
}

export default function LeftImageRightText({
  body,
  image,
  title,
  cta,
  backgroundColor,
  rightImage,
}: LeftImageRightTextProps): ReactElement {
  const fontColor = backgroundColor === 'primary' ? 'white' : 'black';

  return (
    <div className={`bg-${backgroundColor} text-${fontColor} py-6 lg:py-0`}>
      <div
        className={`w-full flex flex-col-reverse gap-x-8 justify-around lg:py-12 lg:pl-24 lg:pr-12  ${
          rightImage ? 'lg:flex-row-reverse' : 'lg:flex-row'
        }`}
      >
        <div className="self-center my-4 lg:mt-0 w-full lg:my-0 lg:max-w-2xl">
          {image && <Image image={image} query="w=672" classNames="m-auto lg:m-0" />}
        </div>
        <div className="px-4 lg:px-0 lg:self-center lg:w-2/3 xl:w-1/3">
          <h2 className={`font-heading text-2xl lg:text-4xl text-${fontColor}`}>{title}</h2>
          {body !== undefined && <RichText document={body} classNames="text-sm lg:text-lg mt-4" />}
          {cta !== undefined && (
            <CTA
              {...cta.fields}
              backgroundColor={backgroundColor || 'white'}
              classNames={'mt-6 hidden lg:inline-block'}
            />
          )}
        </div>
      </div>
      {cta !== undefined && (
        <div className="lg:hidden mt-1 px-4">
          <CTA {...cta.fields} backgroundColor={backgroundColor || 'white'} />
        </div>
      )}
    </div>
  );
}
